
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





































































.net-gigaprojet__hero {
  ::v-deep {
    .section-hero__content__title {
      @include mq(m) {
        font-size: 5.4rem;
      }
    }

    .section-hero__content__text {
      @include mq(m) {
        font-size: 1.8rem;
      }
    }

    .section-hero__inner {
      @include mq(l) {
        padding-top: $spacing * 4;
      }
    }

    .section-hero__content {
      @include mq(xxs) {
        padding-top: 0;
      }
    }

    .speedlines {
      display: none;
      @include mq(m) {
        display: block;
      }
    }
  }
}

.net-gigaprojet__goals {
  ::v-deep {
    .card__infos {
      justify-content: start !important;
    }
  }
}
