
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





































































































































.newspreview,
[class*='newspreview--'] {
  @include bg-color;
}

.newspreview__title,
.newspreview__excerpt {
  font-family: $ff-alt;
  text-align: center;
}

.newspreview__title {
  margin-top: 0;
  margin-bottom: $spacing;
}

.newspreview__switcher {
  margin: $spacing * 2 0 $spacing * 2.5;
  text-align: center;
}

.newspreview__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include mq(s) {
    margin: 0 -#{$spacing};
  }
}

.newspreview__list__item {
  width: 100%;
  margin: $spacing 0;

  ::v-deep {
    .card__infos {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex: 1;
    }

    .card__infos__title {
      margin-bottom: $spacing;
      font-size: 2.2rem;
      white-space: break-spaces;
    }
  }

  @include mq(s) {
    width: calc(50% - #{$spacing * 2});
    margin: $spacing;
  }

  @include mq(l) {
    width: calc(33.33% - #{$spacing * 2});
  }
}

.newspreview__link {
  margin-top: $spacing * 1.5;
  text-align: right;
}
